

.split-view {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

}

.submission-view {
  display: flex;
  width: 100vw;
}

.nearby-view {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

@media(max-width: 800px) {
  .split-view {
    flex-direction: column;
  }
}

ul.nearby-list {
  padding: 0;
  white-space: pre-wrap;
}

ul.nearby-list li {
  list-style-type: none;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.nearby-list p {
  margin: 0;
}

ul.nearby-list li:hover {
    box-shadow: 0 2px 6px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
}

ul.nearby-list .selected {
  background-color: #317afa37;
}

ul.nearby-list .subtitle {
  color: #aaaaaa;
  font-size: 14px;
}

ul.nearby-list .title {
  font-size: 20px;
  font-weight: bold;
}

.nearby-map {
  display: flex;
  min-height: 400px;
  height: 50vh;
}

.info-span {
  display: block;
  text-align: left;
  color: #555555;
  font-size: 2vh;
}

.bold {
  font-weight: bold;
}
