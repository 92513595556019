.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-menus {
  display: flex;
  flex-grow: 1;
}

.App-title {
  display: flex;
  font-size: 22px;
}

.App-link {
  text-decoration: none;
  display: flex;
  color: #61dafb;
  font-size: 16px;
  padding: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
