
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.list-item {
  width: 300px;
  flex-grow: 0.5;
  display: flex;
  flex-direction: column;
}
