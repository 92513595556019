.submission-list {
  display:flex;
  flex-wrap: wrap;
  width: 100vw;
  justify-content: space-evenly;
  justify-items: flex-start;
  align-items: flex-start;
}

.submission-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 20vw;
  padding: 5px;
  margin:0px;
  margin-bottom: 10px;
  align-items: flex-start;
  border: 1px whitesmoke solid;
}

@media(max-width: 1200px) {
  .submission-list {
    justify-content: space-evenly;
  }
  .submission-item {
    width: 45vw;
    margin:0px
  }
}
@media(max-width: 600px) {
  .submission-list {
    justify-content: space-evenly;
  }
  .submission-item {
    width: 95vw;
  }
}

.submission-link {
  margin:0;
  padding:0;
  text-decoration: none;
}


.submission-title {
  color: black;
  font-size: 18px;
}
