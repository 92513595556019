.image-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;

}

.preview-json {
  text-align: start;
  align-content: flex-start;
  display: flex;
  max-width: 100%;
}

.image-list img{
  padding: 10px;
}
